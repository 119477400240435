html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@keyframes blink {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

:root {
  color-scheme: light only !important;
}

:target {
  scroll-margin-top: 24px;
}

.grecaptcha-badge {
  visibility: hidden;
  display: none;
}




body {
  position: relative;
}

*:has(.hover-banner):hover > .hover-banner {
  background-color: #C29B40;
}

.yarl__navigation_next:hover, .yarl__navigation_prev:hover {
  background-color: white !important;
}

.yarl__thumbnails_thumbnail {
  background: none !important;
}

.osano-cm-widget{
  display: none;
}

#chatlio-widget {
  display: none;
}

.chatlio-widget.chatlio-closed .chatlio-title-bar-button {
  display: none !important;
  visibility: hidden !important;
}